import React, { useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
const me = `
 let me = {
  name:'Pramod Morthala'
 }
`
const Header = ({ name, location, phone, email, image }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const handleVibe=(e)=>{
    setExpanded(false)
    if (navigator.vibrate) { 
      window.navigator.vibrate([100, 30, 100]); 
  } 
  }

  return (
    <Navbar expand="md" className="header">
      <Container className="header-container">
        <Link to="/" className="mb-md-2 mb-lg-5">
          <div className="name-header">Pramod Morthala</div>
          <span className="desig-header">Fontend Developer</span>
        </Link>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} /> */}
        {/* <Navbar.Collapse id="basic-navbar-nav" className={expanded ? 'show' : ''}> */}
        <Nav className="flex-column">
          <NavLink
          to="/"
          onClick={handleVibe}
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active-link" : ""
          }
          >
          <pre>
            <code className="name-as-object">{me}</code>
         </pre>
          </NavLink>
          <NavLink
            to="/about"
            onClick={handleVibe}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active-link" : ""
            }
          >
            About
          </NavLink>
          <NavLink
            to="/skills"
            onClick={handleVibe}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active-link" : ""
            }
          >
            Skills
          </NavLink>
          <NavLink
            to="/projects"
            onClick={handleVibe}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active-link" : ""
            }
          >
            Projects
          </NavLink>
          {/* <NavLink to="/education" onClick={() => setExpanded(false)}>Education</NavLink> */}
          <NavLink
            to="/contact"
            onClick={handleVibe}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active-link" : ""
            }
          >
            Contact
          </NavLink>
        </Nav>
        {/* </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
};

export default Header;
